<script>
import codigosCIE10 from "./optionsCodigoCIE10";
import EPSs from "./EPSs";
// const Swal = require("sweetalert2");

export default {
  mounted() {
    this.formDataBase.codigosCIE10 = codigosCIE10;
    this.formDataBase.EPSs = EPSs;
  },
  data() {
    return {
      worker: {
        searching: false,
        imputSearchWorker: null,
        stateErrorWorker: null,
        about: null,
      },
      hasForm: false,
      formDataBase: {
        tipoDeEvento: [
          { value: null, text: "Seleccionar" },
          { value: "EG", text: "EG" },
          { value: "AT", text: "AT" },
          { value: "EL", text: "EL" },
        ],
        codigosCIE10: null,
        EPSs: null,
      },
      newForm: {},
      incapacidades: [],
    };
  },
  methods: {
    // calculoNuevoItem() {
    //   setTimeout(() => {
    //     if (this.newForm.tipoDeEvento == "EG") {
    //       //"Enfermedad General / Incapacidad común"
    //       this.newForm.total = (this.worker.about.salario / 30) * this.diffDate() * this.porcenta3;
    //       this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
    //     }
    //     if (this.newForm.tipoDeEvento == "AT") {
    //       // Accidente de Trabajo / Incapacidad Laboral
    //       this.nuevoItem.total = (this.worker.about.salario / 30) * this.diffDate();
    //       this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
    //     }
    //     if (this.newForm.tipoDeEvento == "EL") {
    //       // Enfermedad Laboral / Incapacidad Laboral
    //       this.nuevoItem.total = (this.worker.about.salario / 30) * this.diffDate();
    //       this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
    //     }
    //     console.log("cal", this.nuevoItem.total);
    //   }, 1000);
    // },
    searchingWorker() {
      this.worker.searching = true;

      this.$store
        .dispatch("get", {
          path: `Persona/GetPersonaCC/${this.worker.imputSearchWorker}`,
        })
        .then((result) => {
          return new Promise((resolve, reject) => {
            result = result.traceId ? null : result;
            if (result) {
              resolve(result);
            } else {
              reject("Trabajador no encontrado");
            }
          });
        })
        .then(async (result) => {
          this.worker.stateErrorWorker = null;
          this.worker.about = result;
          this.initIncapacidades(result.id);
        })
        .catch((error) => {
          this.worker.stateErrorWorker = false;
          this.worker.about = null;
          console.error(error);
        })
        .finally(() => {
          this.worker.searching = false;
        });
    },
    async initIncapacidades(personaId) {
      let result = await this.$store.dispatch("get", {
        path: `RegistroDeIncapacidad/Persona/${personaId}`,
      });
      this.incapacidades = result;
    },

    initNewForm() {
      this.newForm = {
        tipoDeEvento: null,
        fechaInicio: null,
        fechaFinal: null,
        codigoCIE10: null,
        salarioBase: null,
        EPS: null,
        entidadDeSalud: null,
        observaciones: null,
        fechaDePresentacion: null,
      };
    },
    createForm() {
      this.hasForm = true;
      this.initNewForm();
    },
    cancelForm() {
      this.hasForm = false;
    },
    async handleForm() {
      //post
      const model = {
        PersonaId: this.worker.about.id,
        FechaInicio: this.newForm.fechaInicio,
        FechaFinal: this.newForm.fechaFinal,
        FechaDePresentacion: this.newForm.fechaDePresentacion,
        TipoDeEvento: this.newForm.tipoDeEvento,
        CodigoCIE10: this.newForm.codigoCIE10,
        EPS: this.newForm.EPS,
        EntidadDeSalud: this.newForm.entidadDeSalud,
        Observaciones: this.newForm.observaciones,
        Salario: this.worker.about.salario,
      };

      const response = await this.$store.getters.fetchPost({ path: "RegistroDeIncapacidad", data: model });
      const result = await response.json();
      console.log(result);

      // this.$store.dispatch("post", {
      //   path: "RegistroDeIncapacidad",
      //   data: model,
      // });
      // Swal.fire({
      //   icon: "success",
      //   title: "Incapacidad registrada",
      //   showConfirmButton: false,
      //   timer: 1500,
      // });

      this.initIncapacidades(this.worker.about.id);
      this.initNewForm();
      this.hasForm = false;

      //getAll
    },
  },
  computed: {
    diffDate() {
      if (this.newForm.fechaFinal && this.newForm.fechaInicio) {
        const days = (new Date(this.newForm.fechaFinal).getTime() - new Date(this.newForm.fechaInicio).getTime()) / (1000 * 60 * 60 * 24);
        return days + 1;
      }
      return 0;
    },
    descriptionCodigoCIE10() {
      return this.formDataBase.codigosCIE10.find((item) => item.value === this.newForm.codigoCIE10);
    },
    salarioBaseDia() {
      return Number.parseFloat(this.newForm.salarioBase / 30).toFixed(2);
    },
  },
  filters: {
    formatDate(value) {
      //formate fecha de la forma 2020-12-31T00:00:00 a 31/12/2020
      return value.split("T")[0].split("-").reverse().join("/");
    },
  },
};
</script>
<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="text-white"><b> Registro de incapacidades</b></h4>
          </template>
          <div class="card-body">
            <h4 class="card-title text-white mb-3"><b>Trabajador</b></h4>

            <b-form @submit.prevent="searchingWorker">
              <!-- Busqueda por CC, nombre y apellidos -->
              <b-form-group
                label-for="imputSearchWorker"
                label="Cédula del trabajador"
                invalid-feedback="Trabajador no encontrado"
                description="Cédula de ciudadanía / Nombres y apellidos"
                :state="worker.stateErrorWorker"
              >
                <b-form-input
                  id="imputSearchWorker"
                  v-model="worker.imputSearchWorker"
                  :state="worker.stateErrorWorker"
                  type="text"
                  placeholder="Agregar información de busqueda"
                  required
                ></b-form-input>
              </b-form-group>
              <b-button type="submit" variant="outline-info" :disabled="worker.searching"><i class="fas fa-search"></i> Buscar</b-button>
            </b-form>

            <!-- Si existe el trabajador se mostrara la siguiente informacion -->
            <article v-if="worker.about" class="mt-4">
              <h4 class="my-3">Información del trabajador</h4>
              <b-list-group class="info-worker my-3">
                <b-list-group-item class="flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h4 class="mb-1">{{ worker.about.apellidos }} {{ worker.about.nombres }}</h4>
                  </div>
                  <p><strong>Cédula de ciudadanía: </strong>{{ worker.about.numeroDocumento }}</p>
                  <p><strong>Número de incapacidades laborales: </strong>{{ incapacidades.length }}</p>

                  <!-- <p><strong>Fecha: </strong>{{ worker.about.fecha }}</p>
                                <p><strong>Cargo: </strong>{{ worker.about.cargo }}</p>
                                <p><strong>Tipo de trabajador: </strong>{{ worker.about.tipoDeTrabajador }}</p>
                                <p><strong>Área / Obra: </strong>{{ worker.about.area }}</p> -->
                </b-list-group-item>
              </b-list-group>

              <div class="mt-4" v-if="incapacidades.length">
                <h4 class="mb-3">Registro de incapacidades</h4>
                <b-list-group>
                  <b-list-group-item v-for="(incapacidad, index) in incapacidades" :key="incapacidad.id">
                    <h4 class="mb-3">incapacidad - {{ index + 1 }}</h4>

                    <p>
                      <strong>Descripción:</strong>
                      {{ incapacidad.tipoDeEvento }}
                    </p>
                    <p>
                      <strong>Fecha inicio:</strong>
                      {{ incapacidad.fechaInicio | formatDate }}
                    </p>
                    <p>
                      <strong>Fecha final:</strong>
                      {{ incapacidad.fechaFinal | formatDate }}
                    </p>
                    <p>
                      <strong>Código CIE10:</strong>
                      {{ incapacidad.codigoCIE10 }}
                    </p>
                    <p><strong>EPS:</strong> {{ incapacidad.eps }}</p>
                    <p>
                      <strong>Entidad de salud:</strong>
                      {{ incapacidad.entidadDeSalud }}
                    </p>
                    <p>
                      <strong>Observaciones:</strong>
                      {{ incapacidad.observaciones }}
                    </p>
                    <p>
                      <strong>Fecha de presentación:</strong>
                      {{ incapacidad.fechaDePresentacion | formatDate }}
                    </p>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </article>
          </div>
        </card>
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-white"><b>Registrar incapacidad</b></h4>
          </template>
          <div class="card-body">
            <div class="d-flex justify-content-center">
              <article class="nueva-incapacidad">
                <vs-button style="width: 250px;" success   v-show="!hasForm" @click="createForm" :disabled="!worker.about" ><i class="fas fa-plus mr-1"></i> Agregar nueva enfermedad laboral<template #animate> <i class="fas fa-plus"></i></template></vs-button>
                <vs-button style="width: 250px;" danger   v-show="hasForm" @click="cancelForm" ><i class="fas fa-xmark pr-1"></i> Cancelar<template #animate> <i class="fas fa-xmark"></i></template></vs-button>
              </article>
            </div>
            <b-form v-if="hasForm" @submit.prevent="handleForm" class="my-4">
              <div class="grid-container">
                <!-- tipo de evento -->
                <b-form-group label-for="tipoDeEvento" label="Tipo de evento">
                  <b-form-select id="tipoDeEvento" v-model="newForm.tipoDeEvento" :options="formDataBase.tipoDeEvento" required></b-form-select>
                </b-form-group>
                <!-- fecha de entrega -->
                <b-form-group label-for="fechaDePresentacion" label="Fecha de entrega de incapacidad del trabajador">
                  <b-form-input type="date" id="fechaDePresentacion" v-model="newForm.fechaDePresentacion" required></b-form-input>
                </b-form-group>
                <!-- fecha inicio -->
                <b-form-group label-for="fechaInicio" label="Fecha inicio de incapacidad">
                  <b-form-input type="date" id="fechaInicio" :max="newForm.fechaFinal" v-model="newForm.fechaInicio" required></b-form-input>
                </b-form-group>
                <!-- fecha fin -->
                <b-form-group label-for="fechaFinal" label="Fecha final de incapacidad">
                  <b-form-input type="date" id="fechaFinal" :min="newForm.fechaInicio" v-model="newForm.fechaFinal" required></b-form-input>
                </b-form-group>
                <!-- dias totales -->
                <div v-show="diffDate" class="px-4 pb-4">
                  <p>
                    <strong>Días totales de incapacidad: </strong>
                    {{ diffDate }}
                  </p>
                </div>

                <!-- codigoCIELO -->
                <div>
                  <b-form-group label-for="codigoCIE10" label="Código CIE 10">
                    <b-form-input type="text" list="codigoCIE10List" id="codigoCIE10" v-model="newForm.codigoCIE10" required></b-form-input>
                    <b-form-datalist id="codigoCIE10List" :options="formDataBase.codigosCIE10"></b-form-datalist>
                  </b-form-group>
                  <div v-if="descriptionCodigoCIE10.value" class="px-4 pb-4">
                    <p>
                      <strong>{{ descriptionCodigoCIE10.value }}: </strong>
                      {{ descriptionCodigoCIE10.text }}
                    </p>
                  </div>
                </div>
                <!-- EPS -->
                <b-form-group label-for="EPS" label="EPS">
                  <b-form-input type="text" list="EPSList" id="EPS" v-model="newForm.EPS" required></b-form-input>
                  <b-form-datalist id="EPSList" :options="formDataBase.EPSs"></b-form-datalist>
                </b-form-group>

                <!-- entidad salud -->
                <b-form-group label-for="entidadDeSalud" label="Entidad de salud en donde se generó la incapacidad" description=" Hospital / Centro médico / IPS /">
                  <b-form-input type="text" id="entidadDeSalud" v-model="newForm.entidadDeSalud" required></b-form-input>
                </b-form-group>
                <!-- observaciones -->
                <b-form-group label-for="observaciones" label="Observaciones">
                  <b-form-textarea id="observaciones" v-model="newForm.observaciones" rows="3" max-rows="6"></b-form-textarea>
                </b-form-group>
              </div>
              <!-- <div>
                            <b-form-group label-for="salarioBase" label="Salario base">
                                <b-form-input
                                type="number" min="0"
                                id="salarioBase"
                                v-model="newForm.salarioBase"
                                required
                                >
                                </b-form-input>
                            </b-form-group>
                            <div v-if="newForm.salarioBase" class="px-4 pb-4">
                                <p><strong>Salario base día: </strong> {{ salarioBaseDia }} $</p>
                            </div>
                        </div> -->
              <div class="w-100 d-flex justify-content-end">
                <b-button type="submit" class="mt-3" variant="outline-success"><i class="fas fa-check"></i> Registrar</b-button>
              </div>
            </b-form>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
</style>
